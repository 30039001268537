import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { CrmIntegrationSettings } from "./CrmIntegrationSettings"
import gongLogo from "../../assets/gong-square.png"
import zoomLogo from "../../assets/zoom-square.png"
import googleCalendarLogo from "../../assets/google-calendar.png"
import microsoftCalendarLogo from "../../assets/outlook-calendar.png"
import aircallLogo from "../../assets/aircall.png"
import LoadingSpinner from "../common/LoadingSpinner"
import { InviteBotBanner } from "../InviteBotBanner"
import { IntegrationSetting } from "./IntegrationSetting"
import {
    MeetingBotConfig,
    setDefaultRecordingPreferences,
} from "../settings/MeetingBotConfig"
import { InvitationSettings } from "./InvitationSettings"
import { NotificationPreferences } from "./NotificationPreferences"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { Card } from "./Card"
import { SlackSettings } from "./SlackSettings"
import {
    deleteOAuthAccessToken,
    generateOAuthAccessToken,
    navigateToExternal,
} from "./oauth_utils"
import { ZoomMeetingSDKSettings } from "./ZoomMeetingSDKSettings"
import { OrgRecordingPreferences } from "./OrgRecordingPreferences"
import { TabHead } from "../common/Tabs"
import { useUser } from "../../providers/UserProvider"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { CalendarType, useIntegrations } from "../../hooks/useIntegrations"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { QuickActionLibrary } from "./QuickActionLibrary"
import { CallTags } from "./CallTags"

enum Tabs {
    Personal = "Personal",
    Organizational = "Organizational",
    UserManagement = "User Management",
    QuickActions = "Quick Action Library",
    CallTags = "Call Tags",
}
const TAB_CACHE_KEY = "settings-tab-index"

export function Settings() {
    const queryClient = useQueryClient()
    const user = useUser()
    const canEditOrgSettings =
        user && hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)
    const canConnectCalendar =
        user && hasPermission(user, Permission.RECORD_CALLS)
    const tabs = [
        {
            type: Tabs.Personal,
            label: Tabs.Personal.toString(),
            tooltip: "These settings affect you alone.",
        },
        {
            type: Tabs.Organizational,
            label: Tabs.Organizational.toString(),
            tooltip: canEditOrgSettings
                ? "These settings affect everyone in your organization."
                : "Organizational settings are restricted to users with Admin permissions",
            disabled: !canEditOrgSettings,
        },
        {
            type: Tabs.UserManagement,
            label: Tabs.UserManagement.toString(),
            tooltip: canEditOrgSettings
                ? "These settings affect everyone in your organization."
                : "Organizational settings are restricted to users with Admin permissions",
            disabled: !canEditOrgSettings,
        },
        {
            type: Tabs.QuickActions,
            label: Tabs.QuickActions.toString(),
            tooltip:
                "View and manage personal and organization-wide Ask Glyphic Quick Actions.",
        },
        {
            type: Tabs.CallTags,
            label: Tabs.CallTags.toString(),
            tooltip: "View and manage organization-wide call tags.",
        },
    ]

    const [activeTab, setActiveTab] = useState<number>(
        getCacheValue(TAB_CACHE_KEY, 0)
    )

    const { orgName, hasGong, hasZoom, hasSlack, hasAircall, isPending } =
        useIntegrations()
    const hasExternalCallRecorder = hasGong || hasZoom

    useEffect(() => {
        document.title = "Settings - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    if (isPending) {
        return <LoadingSpinner />
    }

    const refetchOrg = () => {
        queryClient.refetchQueries({
            queryKey: ["organization"],
        })
    }
    return (
        <div className="flex flex-col px-6 py-6 space-y-6">
            <h1 className="text-3xl font-bold">Settings</h1>
            <div className="w-fit">
                <TabHead
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabChange={(index: number) => {
                        setActiveTab(index)
                        setCacheValue(TAB_CACHE_KEY, index)
                    }}
                />
            </div>
            {tabs[activeTab].type === Tabs.Personal && (
                <PersonalSettings
                    canConnectCalendar={!!canConnectCalendar}
                    hasExternalCallRecorder={hasExternalCallRecorder}
                    hasSlackIntegration={hasSlack}
                />
            )}

            {tabs[activeTab].type === Tabs.Organizational &&
                canEditOrgSettings && (
                    <OrgSettings
                        orgName={orgName}
                        hasGongIntegration={hasGong}
                        hasZoomIntegration={hasZoom}
                        hasAirCallIntegration={hasAircall}
                        hasSlackIntegration={hasSlack}
                        onIntegrationChange={refetchOrg}
                    />
                )}

            {tabs[activeTab].type === Tabs.UserManagement &&
                canEditOrgSettings && <InvitationSettings orgName={orgName} />}
            {tabs[activeTab].type === Tabs.QuickActions && (
                <QuickActionLibrary />
            )}
            {tabs[activeTab].type === Tabs.CallTags && <CallTags />}
        </div>
    )
}

function PersonalSettings(props: {
    canConnectCalendar: boolean
    hasExternalCallRecorder: boolean
    hasSlackIntegration: boolean
}) {
    const { hasGoogleCal, hasMicrosoftCal } = useIntegrations()

    return (
        <>
            {props.canConnectCalendar && (
                <Card
                    title="Calendar integration"
                    label="Enable call preparation and call processing by
            connecting your calendar."
                >
                    <GoogleCalendarSettings
                        authorized={hasGoogleCal}
                        hasExternalCallRecorder={props.hasExternalCallRecorder}
                    />
                    <MicrosoftCalendarSettings
                        authorized={hasMicrosoftCal}
                        hasExternalCallRecorder={props.hasExternalCallRecorder}
                    />
                    <InviteBotBanner />
                    <ZoomMeetingSDKSettings />
                </Card>
            )}

            <NotificationPreferences slackEnabled={props.hasSlackIntegration} />
        </>
    )
}

function OrgSettings(props: {
    orgName: string | undefined
    hasGongIntegration: boolean
    hasZoomIntegration: boolean
    hasSlackIntegration: boolean
    hasAirCallIntegration: boolean
    onIntegrationChange: () => void
}) {
    return (
        <>
            <div id="crm">
                <CrmIntegrationSettings />
            </div>
            <Card
                title="Messaging"
                label="Connect Glyphic to your Slack workspace. This will give your team access to call prep sheets and call analysis right in Slack"
            >
                <SlackSettings
                    authorized={props.hasSlackIntegration}
                    setAuthorized={props.onIntegrationChange}
                />
            </Card>
            <OrgRecordingPreferences orgName={props.orgName} />
            <Card
                title="Call recorder"
                label="Integrate a company wide call recorder to import past calls & process upcoming calls."
            >
                <GongSettings
                    authorized={props.hasGongIntegration}
                    setAuthorized={props.onIntegrationChange}
                />
                <ZoomSettings
                    authorized={props.hasZoomIntegration}
                    setAuthorized={props.onIntegrationChange}
                />
                <AircallSettings
                    authorized={props.hasAirCallIntegration}
                    setAuthorized={props.onIntegrationChange}
                />
            </Card>
        </>
    )
}

function GongSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const oauthCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?gongCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("gongCallback") === "true") {
                // If we're loading settings page from Gong OAuth callback,
                // send req to backend to generate auth token
                const gongAuthCode = searchParams.get("code")!
                if (gongAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "gong_token",
                        gongAuthCode,
                        oauthCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                // If there is no gongAuthCode passed in the callback, the user has likely denied granting access
                navigate("/settings")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, oauthCallbackUrl])

    async function deleteGongToken() {
        const success = await deleteOAuthAccessToken("gong_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Gong"
            logo={gongLogo}
            onEnable={async () => navigateToExternal(gongUrl(oauthCallbackUrl))}
            onDisable={deleteGongToken}
            authorized={props.authorized}
        />
    )
}

function gongUrl(callbackUrl: string): string {
    const gongUrlPrefix = "https://app.gong.io/oauth2/authorize?"

    let url = new URL(gongUrlPrefix)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_GONG_COPILOT_APP_ID!
    )
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", callbackUrl)

    return url.toString()
}

function ZoomSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const zoomCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?zoomCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("zoomCallback") === "true") {
                // If we're loading settings page from Zoom OAuth callback,
                // send req to backend to generate auth token
                const zoomAuthCode = searchParams.get("code")!
                if (zoomAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "zoom_token",
                        zoomAuthCode,
                        zoomCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                // If there is no zoomAuthCode passed in the callback, the user has likely denied granting access
                navigate("/settings")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, zoomCallbackUrl])

    async function deleteZoomToken() {
        const success = await deleteOAuthAccessToken("zoom_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Zoom Cloud Recordings"
            logo={zoomLogo}
            onEnable={async () => navigateToExternal(zoomUrl())}
            onDisable={async () => deleteZoomToken()}
            authorized={props.authorized}
        />
    )
}

function AircallSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const aircallCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?aircallCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("aircallCallback") === "true") {
                const aircallAuthCode = searchParams.get("code")!
                if (aircallAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "aircall_token",
                        aircallAuthCode,
                        aircallCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                navigate("/settings")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, aircallCallbackUrl])

    async function deleteAircallToken() {
        const success = await deleteOAuthAccessToken("aircall_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Aircall"
            logo={aircallLogo}
            onEnable={async () => {
                navigateToExternal(aircallUrl())
            }}
            onDisable={async () => deleteAircallToken()}
            authorized={props.authorized}
        />
    )
}

function zoomUrl() {
    const zoomUrlPrefix = "https://zoom.us/oauth/authorize?"
    const redirect_uri = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?zoomCallback=true`

    let url = new URL(zoomUrlPrefix)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_ZOOM_COPILOT_APP_ID! // TODO replace with production client ID?
    )
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", redirect_uri)

    return url.toString()
}

function aircallUrl() {
    const aircallUrlPrefix = "https://dashboard.aircall.io/oauth/authorize?"
    const redirect_uri = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?aircallCallback=true`

    let url = new URL(aircallUrlPrefix)
    url.searchParams.set("client_id", process.env.REACT_APP_AIRCALL_CLIENT_ID!)
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set("scope", "public_api")

    return url.toString()
}

function GoogleCalendarSettings(props: {
    authorized: boolean
    hasExternalCallRecorder: boolean
}) {
    const [searchParams] = useSearchParams()
    const { addNotification } = useNotification()
    const [showConfig, setShowConfig] = useState<boolean>(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("googleCalendarCallback") === "true") {
                const callbackError = searchParams.get("error")
                if (callbackError) {
                    addNotification(
                        "Error from Google Calendar!",
                        callbackError,
                        NotificationType.Error
                    )
                    return
                }

                // We do not want the user to refresh the page, go back or be
                // suggested by the browser and trigger this again.
                window.history.replaceState({}, "", "/settings")

                await setDefaultRecordingPreferences(
                    props.hasExternalCallRecorder
                )
                refreshAuthStatus(queryClient, CalendarType.Google)
                setShowConfig(true) // We only want to display config popup *after* default prefs have been set
            }
        }
        generateTokenFromCallback()
    }, [
        searchParams,
        queryClient,
        setShowConfig,
        addNotification,
        props.hasExternalCallRecorder,
    ])

    return (
        <IntegrationSetting
            name="Google Calendar"
            logo={googleCalendarLogo}
            onEnable={async () => enableGoogleCalendar()}
            onDisable={async () => {
                await disableCalendar(CalendarType.Google)
                await refreshAuthStatus(queryClient, CalendarType.Google)
            }}
            authorized={props.authorized}
            config={
                <MeetingBotConfig
                    hasExternalCallRecorder={props.hasExternalCallRecorder}
                />
            }
            showConfig={showConfig}
        />
    )
}

async function enableGoogleCalendar() {
    try {
        const calendarAccessToken = await generateCalendarAccessToken()
        navigateToExternal(googleCalendarAuthUrl(calendarAccessToken))
    } catch (error) {
        console.log(error)
    }
}

async function disableCalendar(calendarType: CalendarType) {
    try {
        await axios.delete(
            `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/${calendarType}`
        )
    } catch (error) {
        console.log(error)
    }
}

function refreshAuthStatus(
    queryClient: QueryClient,
    calendarType: CalendarType
) {
    queryClient.refetchQueries({
        queryKey: ["calendar", calendarType],
    })
}

function googleCalendarAuthUrl(calendarAccessToken: string) {
    // This follows https://recallai.readme.io/reference/google-calendar-setup

    // Google OAuth must first redirect to an API owned by us, which we forward to Recall
    const redirect_uri = `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/google/oauth_callback`
    const state = JSON.stringify({
        recall_calendar_auth_token: calendarAccessToken,
        google_oauth_redirect_url: redirect_uri,
        success_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?googleCalendarCallback=true`,
        error_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings`,
    })

    const urlPrefix = "https://accounts.google.com/o/oauth2/v2/auth"
    let url = new URL(urlPrefix)
    url.searchParams.set(
        "scope",
        "https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/userinfo.email"
    )
    url.searchParams.set("access_type", "offline")
    url.searchParams.set("prompt", "consent")
    url.searchParams.set("include_granted_scopes", "true")
    url.searchParams.set("response_type", "code")
    url.searchParams.set("state", state)
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_GOOGLE_CANENDAR_APP_ID!
    )

    return url.toString()
}

function MicrosoftCalendarSettings(props: {
    authorized: boolean
    hasExternalCallRecorder: boolean
}) {
    const [searchParams] = useSearchParams()
    const { addNotification } = useNotification()
    const [showConfig, setShowConfig] = useState<boolean>(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("microsoftCalendarCallback") === "true") {
                // We do not want the user to refresh the page, go back or be
                // suggested by the browser and trigger this again.
                window.history.replaceState({}, "", "/settings")

                await setDefaultRecordingPreferences(
                    props.hasExternalCallRecorder
                )
                refreshAuthStatus(queryClient, CalendarType.Microsoft)
                setShowConfig(true) // We only want to display config popup *after* default prefs have been set
            } else if (
                searchParams.get("microsoftCalendarCallback") === "false"
            ) {
                const callbackError = searchParams.get("error")
                const errorMsg = callbackError || "Unknown Error"
                addNotification(
                    "Error from Outlook Calendar!",
                    errorMsg,
                    NotificationType.Error
                )
                return
            }
        }
        generateTokenFromCallback()
    }, [
        searchParams,
        queryClient,
        setShowConfig,
        addNotification,
        props.hasExternalCallRecorder,
    ])

    return (
        <IntegrationSetting
            name="Microsoft Outlook Calendar"
            logo={microsoftCalendarLogo}
            onEnable={async () => enableMicrosoftCalendar()}
            onDisable={async () => {
                await disableCalendar(CalendarType.Microsoft)
                await refreshAuthStatus(queryClient, CalendarType.Microsoft)
            }}
            authorized={props.authorized}
            config={
                <MeetingBotConfig
                    hasExternalCallRecorder={props.hasExternalCallRecorder}
                />
            }
            showConfig={showConfig}
        />
    )
}

async function enableMicrosoftCalendar() {
    try {
        const calendarAccessToken = await generateCalendarAccessToken()
        navigateToExternal(microsoftCalendarAuthUrl(calendarAccessToken))
    } catch (error) {
        console.log(error)
    }
}

function microsoftCalendarAuthUrl(calendarAccessToken: string) {
    // This follows https://recallai.readme.io/reference/microsoft-outlook-calendar-setup

    // Microsoft OAuth must first redirect to an API owned by us, which we forward to Recall
    const redirect_uri = `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/microsoft/oauth_callback`
    const state = JSON.stringify({
        recall_calendar_auth_token: calendarAccessToken,
        ms_oauth_redirect_url: redirect_uri,
        success_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?microsoftCalendarCallback=true`,
        error_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?microsoftCalendarCallback=false`,
    })

    const urlPrefix =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
    let url = new URL(urlPrefix)
    url.searchParams.set(
        "scope",
        "offline_access openid email https://graph.microsoft.com/Calendars.Read"
    )
    url.searchParams.set("response_mode", "query")
    url.searchParams.set("response_type", "code")
    url.searchParams.set("state", state)
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_OUTLOOK_CANENDAR_APP_ID!
    )

    return url.toString()
}

async function generateCalendarAccessToken(): Promise<string> {
    // Generate Recall's calendar access token for the user
    const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/auth`
    )
    return response.data.token
}
