import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ColoredLabelSquare({
    color,
    isSelected,
}: {
    color: string
    isSelected: boolean
}) {
    return (
        <div
            className="w-3 h-3 mr-2 rounded-sm flex-0 relative flex-shrink-0"
            style={{ backgroundColor: color }}
        >
            {isSelected && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="text-white text-xs"
                    />
                </div>
            )}
        </div>
    )
}
