import { PieChart, Pie, ResponsiveContainer, Cell, Sector } from "recharts"
import { useId, useMemo, useState } from "react"
import clsx from "clsx"
import { ColoredLabelSquare } from "./common/ColoredLabelSquare"
import { getColorTable } from "./utils/colors"
import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { LossReason } from "../../types/Insights"
import { ILossReasons } from "../../types/Insights"
import { LoadingPulse } from "../common/LoadingPulse"
import { queries } from "../../api/queries"
import { DealsTooltip } from "./common/DealsTooltip"
import { IStrategicInsightNavigationItem } from "./utils/navigation"

const COLORS = getColorTable("500")

export const LOSS_REASONS_NAV_ITEM: IStrategicInsightNavigationItem = {
    id: "loss-reasons",
    displayName: "Deal Loss Reasons",
    isSummary: true,
}

export function LossReasons(props: {
    lossReasonsQueryResult: UseQueryResult<ILossReasons, Error>
}) {
    const result = props.lossReasonsQueryResult
    const [activeIndex, setActiveIndex] = useState<number | undefined>()
    const activeLossReason = useMemo(() => {
        return activeIndex === undefined
            ? undefined
            : result.data?.loss_reasons[activeIndex]
    }, [activeIndex, result.data])

    if (result.isPending) return <LoadingPulse rows={2} height="h-96" />

    if (!canShowLossReasons(result.data)) {
        return null
    }

    return (
        <div
            className="flex flex-col bg-white rounded-lg p-6 border border-gray-200 w-full"
            id={LOSS_REASONS_NAV_ITEM.id}
        >
            <h2 className="text-2xl font-bold mb-3 text-gray-800">
                {LOSS_REASONS_NAV_ITEM.displayName}
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full bg-gray-50 rounded-lg p-4">
                <Legend
                    data={result.data.loss_reasons}
                    totalDealCount={result.data.total_lost_deals}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
                <div className="flex flex-col h-full">
                    <div className="flex-grow">
                        <LossReasonsChart
                            data={result.data.loss_reasons}
                            totalDealCount={result.data.total_lost_deals}
                            activeLossReasonDealCount={activeLossReason?.count}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        />
                    </div>
                    <Description lossReason={activeLossReason} />
                </div>
            </div>
        </div>
    )
}

function Description(props: { lossReason: LossReason | undefined }) {
    return (
        <div className="h-16 items-center hidden md:flex ">
            {props.lossReason && (
                <span className="text-sm text-gray-500 line-clamp-3">
                    <b>{props.lossReason.loss_category}</b>
                    {": "}
                    {props.lossReason.description}
                </span>
            )}
        </div>
    )
}

export function canShowLossReasons(
    data: ILossReasons | undefined
): data is ILossReasons {
    return data !== undefined && data.total_lost_deals > 0
}

function LossReasonsChart(props: {
    data: LossReason[]
    totalDealCount: number
    activeLossReasonDealCount: number | undefined
    activeIndex: number | undefined
    setActiveIndex: (index: number | undefined) => void
}) {
    function onPieEnter(_: any, index: number) {
        props.setActiveIndex(index)
    }

    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    data={props.data}
                    dataKey="count"
                    nameKey="loss_category"
                    startAngle={90}
                    endAngle={450}
                    innerRadius={"70%"}
                    outerRadius={"90%"}
                    activeShape={renderActiveShape}
                    activeIndex={props.activeIndex}
                    onMouseEnter={onPieEnter}
                    onMouseLeave={() => props.setActiveIndex(undefined)}
                >
                    {props.data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            opacity={
                                props.activeIndex !== undefined &&
                                props.activeIndex !== index
                                    ? 0.5
                                    : 1
                            }
                        />
                    ))}
                </Pie>
                <text
                    x="50%"
                    y="50%"
                    dy={-4}
                    textAnchor="middle"
                    className="text-3xl font-bold"
                >
                    {props.activeLossReasonDealCount || props.totalDealCount}
                </text>
                <text x="50%" y="50%" dy={16} textAnchor="middle">
                    Lost Deals
                </text>
            </PieChart>
        </ResponsiveContainer>
    )
}

function renderActiveShape(props: any) {
    // Copied from https://recharts.org/en-US/examples/CustomActiveShapePieChart
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
        props
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    )
}

function Legend(props: {
    data: LossReason[]
    totalDealCount: number
    activeIndex: number | undefined
    setActiveIndex: (index: number | undefined) => void
}) {
    return (
        <table
            className="w-full table-fixed border-separate border-spacing-y-2"
            onMouseLeave={() => props.setActiveIndex(undefined)}
        >
            <thead className="text-sm">
                <tr>
                    <th className="text-left px-4 ">Loss Reason</th>
                    <th className="text-left px-8 w-16 whitespace-nowrap overflow-visible">
                        Total Deals
                    </th>
                    <th className="w-16"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((lossReason, index) => (
                    <LegendEntry
                        lossReason={lossReason}
                        index={index}
                        totalDealCount={props.totalDealCount}
                        isActive={props.activeIndex === index}
                        isInactive={
                            props.activeIndex !== undefined &&
                            props.activeIndex !== index
                        }
                        setActiveIndex={() => props.setActiveIndex(index)}
                    />
                ))}
            </tbody>
        </table>
    )
}

function LegendEntry(props: {
    lossReason: LossReason
    index: number
    totalDealCount: number
    isActive: boolean
    isInactive: boolean
    setActiveIndex: () => void
}) {
    const percentage = Math.round(
        (props.lossReason.count / props.totalDealCount) * 100
    )
    const tooltipId = useId()

    const deal_ids = props.lossReason.deal_ids
    const { data: deals, isPending: dealsIsPending } = useQuery({
        ...queries.deals.getByIds(deal_ids),
        enabled: props.isActive && !!deal_ids,
        staleTime: Infinity,
    })

    return (
        <tr
            className={clsx(
                "items-center transition-opacity",
                props.isInactive && "opacity-50"
            )}
            onMouseEnter={props.setActiveIndex}
        >
            <td className="px-4" data-tooltip-id={tooltipId}>
                <span className="flex items-center gap-2">
                    <ColoredLabelSquare
                        key={props.lossReason.loss_category}
                        color={COLORS[props.index % COLORS.length]}
                        isSelected={false}
                    />
                    <span
                        className={clsx(
                            "truncate",
                            props.isActive && "font-bold"
                        )}
                    >
                        {props.lossReason.loss_category}
                    </span>
                </span>
                <DealsTooltip
                    tooltipId={tooltipId}
                    deals={deals ?? []}
                    pendingRowCount={dealsIsPending ? deal_ids.length : 0}
                    place="left"
                    isOpen={props.isActive}
                />
            </td>
            <td className="px-4 text-right">{props.lossReason.count}</td>
            <td className="px-4 font-bold text-right">{percentage}%</td>
        </tr>
    )
}
