import { ColoredLabelSquare } from "../common/ColoredLabelSquare"
import { SelectionState } from "./SelectionState"
import clsx from "clsx"

export function Legend(props: {
    insightName: string
    colors: string[]
    totalCounts: [string, number][]
    selectionState: SelectionState
    toggleSelectedOption: (option: string) => void
    setHoveredOption: (option: string | null) => void
    isOptionSelected: (option: string, state: SelectionState) => boolean
}) {
    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between w-full">
                <span className="text-sm font-bold mb-3 text-gray-800">
                    {props.insightName}
                </span>
                <span className="text-sm font-bold mb-3 text-gray-800">
                    Total
                </span>
            </div>
            {props.totalCounts.map(([option, total], index) => (
                <div
                    key={option}
                    className="flex flex-row justify-between w-full gap-2"
                    onMouseEnter={() => props.setHoveredOption(option)}
                    onMouseLeave={() => props.setHoveredOption(null)}
                >
                    <button
                        className={clsx(
                            "flex items-center mb-2 text-gray-600 hover:font-semibold overflow-hidden w-full",
                            props.isOptionSelected(option, props.selectionState)
                                ? "font-semibold"
                                : "opacity-50 hover:opacity-100"
                        )}
                        onClick={() => props.toggleSelectedOption(option)}
                    >
                        <ColoredLabelSquare
                            color={props.colors[index % props.colors.length]}
                            // We use the props.selectionState.selected
                            // directly so that we don't count with the
                            // hovered state.
                            isSelected={props.selectionState.selected.has(
                                option
                            )}
                        />
                        <span className="flex-1 text-left truncate">
                            {option}
                        </span>
                    </button>
                    <div
                        className={clsx(
                            "w-fit min-w-0",
                            !props.isOptionSelected(
                                option,
                                props.selectionState
                            ) && "opacity-50"
                        )}
                    >
                        <div className="whitespace-nowrap">
                            <span className="font-bold text-gray-600">
                                {total}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
