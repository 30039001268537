import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faLightbulbSlash,
    faLock,
    faLockOpen,
    faMagnifyingGlassMinus,
    faRotateExclamation,
} from "@fortawesome/pro-regular-svg-icons"

import { ICallPreview } from "../../types/Call"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useUser } from "../../providers/UserProvider"
import { getHeapInstance } from "../../utils/heap"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { IUser } from "../../types/User"
import clsx from "clsx"
import { queries } from "../../api/queries"
import { useState } from "react"
import { Modal } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { faLink } from "@fortawesome/free-solid-svg-icons"

export function TogglePrivacyButton({ call }: { call: ICallPreview }) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()
    const user = useUser()

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const togglePrivacy = useMutation({
        mutationFn: async () => {
            getHeapInstance()?.track("call-toggle-privacy-clicked", {
                new_privacy_value: !call.is_private,
            })
            await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${call.id}/privacy`,
                null,
                {
                    params: {
                        is_private: !call.is_private,
                    },
                }
            )
        },
        onSuccess: () => {
            const is_private = !call.is_private
            if (is_private) {
                addNotification(
                    "This call is now private",
                    "Only attendees can view this call.",
                    NotificationType.Success
                )
            } else {
                addNotification(
                    "This call is now public",
                    "Anyone in your organization can view this call.",
                    NotificationType.Success
                )
            }
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(call.id).queryKey,
            })
            queryClient.invalidateQueries({
                queryKey: queries.calls.media(call.id).queryKey,
            })
        },
        onError: () => {
            addNotification(
                "Failed to update call privacy!",
                "Please try again later.",
                NotificationType.Error
            )
        },
    })

    if (!call.can_view) {
        // Only users with permission should be able to make public
        return null
    }

    const targetState = call.is_private ? "public" : "private"
    const canTogglePrivacy = user && userIsAttendee(call, user)

    const confirmTogglePrivacy = () => {
        setShowConfirmationModal(false)
        togglePrivacy.mutate()
    }

    return (
        <>
            <button
                onClick={() => setShowConfirmationModal(true)}
                className={clsx(
                    "flex flex-col items-center text-sm",
                    canTogglePrivacy
                        ? "group text-gray-600 hover:text-gray-800"
                        : "text-gray-400 cursor-not-allowed"
                )}
                disabled={!canTogglePrivacy}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    canTogglePrivacy
                        ? ""
                        : "Only attendees of the call can toggle privacy"
                }
            >
                <AnimatedLockIcon isPrivate={call.is_private} />
                <span className="hidden md:block">{`Make ${targetState}`}</span>
            </button>
            <TogglePrivacyModal
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                targetState={targetState}
                call={call}
                confirmTogglePrivacy={confirmTogglePrivacy}
            />
        </>
    )
}

function TogglePrivacyModal(props: {
    showConfirmationModal: boolean
    setShowConfirmationModal: (showConfirmationModal: boolean) => void
    targetState: "public" | "private"
    call: ICallPreview
    confirmTogglePrivacy: () => void
}) {
    const {
        showConfirmationModal,
        setShowConfirmationModal,
        targetState,
        call,
        confirmTogglePrivacy,
    } = props
    const isExternal = call.companies.length > 0

    const { data: shareCallOptions } = useQuery(
        queries.calls.shareOptions(call.id)
    )
    const isShared = shareCallOptions !== null
    const { data: crmObjects } = useQuery(queries.calls.crmObjects(call.id))
    const isSyncedWithCrm = crmObjects && crmObjects?.length > 0

    return (
        <Modal
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
        >
            <div className="p-3">
                <div className="font-semibold text-lg mb-4 mr-10">
                    {`Are you sure you want to make this call ${targetState}?`}
                </div>
                {targetState === "public" && (
                    <WarningText
                        warning="This call will be visible to everyone in your organization."
                        icon={faLockOpen}
                    />
                )}
                {targetState === "private" && (
                    <WarningText
                        warning="This call will be visible only to attendees."
                        icon={faLock}
                    />
                )}
                {targetState === "private" && (
                    <WarningText
                        warning="It will not be visible in search results"
                        icon={faMagnifyingGlassMinus}
                    />
                )}
                {targetState === "private" && isExternal && (
                    <WarningText
                        warning="This call's data will not be available in linked company and deal pages"
                        icon={faLightbulbSlash}
                    />
                )}
                {targetState === "private" && isSyncedWithCrm && (
                    <WarningText
                        warning="Data already synced to CRM will not be modified."
                        icon={faRotateExclamation}
                    />
                )}
                {targetState === "private" && isShared && (
                    <WarningText
                        warning="The shared call link will still be visible to users with the link."
                        icon={faLink}
                    />
                )}

                <div className="flex justify-end mt-8">
                    <SecondaryButton
                        onClick={() => setShowConfirmationModal(false)}
                        className="mr-4"
                    >
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={confirmTogglePrivacy}>
                        Make {targetState}
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}

function WarningText({ warning, icon }: { warning: string; icon: any }) {
    return (
        <div className="text-sm text-gray-800 bg-gray-200 rounded-lg p-2 mt-2">
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {warning}
        </div>
    )
}

function userIsAttendee(call: ICallPreview, user: IUser) {
    return call.parties.some((party) => party.email === user.email)
}

export function AnimatedLockIcon({ isPrivate }: { isPrivate: boolean }) {
    return (
        <div className="relative w-4 h-4">
            <FontAwesomeIcon
                icon={isPrivate ? faLock : faLockOpen}
                className="absolute inset-0 group-hover:opacity-0 duration-300"
            />
            <FontAwesomeIcon
                icon={isPrivate ? faLockOpen : faLock}
                className="absolute inset-0 opacity-0 group-hover:opacity-100 duration-300"
            />
        </div>
    )
}
