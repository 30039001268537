import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICall, IShareOptions } from "../../types/Call"
import { ICrmObjectReference } from "../../components/crm/types/Crm"

export const callQueries = createQueryKeys("calls", {
    byId: (callId: string) => ({
        queryKey: ["call", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId
            )
            return response.data as ICall
        },
    }),
    media: (callId: string) => ({
        queryKey: ["call/media", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId + "/media"
            )
            return response.data
        },
    }),
    shareOptions: (callId: string) => ({
        queryKey: ["call/share-options", callId],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/share`
            )
            if (response.status === 204) {
                // Call has not been shared before
                return null
            }
            return response.data as IShareOptions
        },
    }),
    crmObjects: (callId: string) => ({
        queryKey: [`call/crm/objects`, callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${callId}/crm/objects`
            )
            return response.data as ICrmObjectReference[]
        },
    }),
})
