import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { LineChart } from "./line-chart/LineChart"
import {
    Granularity,
    InsightResult,
    InsightsResponse,
} from "../../types/Insights"
import { DateTime } from "luxon"
import { useMemo, useState } from "react"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { BetaLabel } from "../common/BetaLabel"
import { DealOutcomesChart } from "./DealOutcomes"
import { MultiSelectFilter } from "../MultiSelectFilter"
import { IOrganizationUser } from "../../types/Organization"
import { LossReasons } from "./LossReasons"
import { submitPylonFeedback } from "../../utils/pylon"
import { LoadingPulse } from "../common/LoadingPulse"
import { Contents } from "./Contents"
import {
    LosingFormulaChart,
    WinningFormulaChart,
} from "./summary-charts/DealPatternsCharts"
import { MostMentionsChart } from "./summary-charts/MostMentionsChart"

export function StrategicInsights() {
    const [sinceDate, setSinceDate] = useState<DateTime>(
        DateTime.now().minus({ months: 12 }).startOf("day")
    )
    const [granularity, setGranularity] = useState<Granularity>(
        Granularity.MONTHLY
    )

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

    const { data: users, isPending: isUsersPending } = useQuery(
        queries.users.list()
    )

    const insightsQuery = useQuery({
        ...queries.insights.get(sinceDate, granularity, selectedUserIds),
        staleTime: Infinity,
    })

    const lossReasonsQuery = useQuery({
        ...queries.insights.getLossReasons(sinceDate, selectedUserIds),
        staleTime: Infinity,
    })

    return (
        <section className="rounded-lg px-8 py-6 space-y-4 flex flex-col lg:h-[calc(100vh)] overflow-hidden">
            <div className="flex flex-col flex-shrink-0 justify-between gap-2">
                <div className="flex flex-row items-center gap-2">
                    <h1 className="text-2xl font-bold">Strategic Insights</h1>
                    <BetaLabel />
                    <BetaNotice />
                </div>
                <p className="text-gray-500">
                    Strategic Insights help you identify trends and
                    opportunities from your{" "}
                    <span className="font-semibold">Glyphic call data</span>.
                </p>
                <div className="flex items-center gap-2 mt-4 space-x-1">
                    <span className="text-gray-900 font-semibold">Date</span>
                    <DateSelect
                        sinceDate={sinceDate}
                        setSinceDate={setSinceDate}
                        setGranularity={setGranularity}
                    />
                    <span className="text-gray-900 font-semibold pl-4">
                        People
                    </span>
                    <PeopleSelect
                        users={users}
                        selectedUserIds={selectedUserIds}
                        setSelectedUserIds={setSelectedUserIds}
                        isLoading={isUsersPending}
                    />
                </div>
            </div>
            <div className="lg:grid grid-cols-5 gap-4 flex-grow min-h-0">
                <div className="col-span-1">
                    <Contents
                        lossReasonsQueryResult={lossReasonsQuery}
                        insightsQueryResult={insightsQuery}
                    />
                </div>
                <div className="col-span-4 space-y-4 overflow-y-scroll scroll-smooth border-t border-b">
                    <LossReasons lossReasonsQueryResult={lossReasonsQuery} />
                    <MostMentionsChart insightsQueryResult={insightsQuery} />
                    <WinningFormulaChart insightsQueryResult={insightsQuery} />
                    <LosingFormulaChart insightsQueryResult={insightsQuery} />
                    <InsightsCharts
                        insightsQueryResult={insightsQuery}
                        sinceDate={insightsQuery.data?.since}
                        granularity={granularity}
                        userIds={selectedUserIds}
                    />
                </div>
            </div>
        </section>
    )
}

function InsightsCharts(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
    sinceDate: string | undefined
    granularity: Granularity
    userIds: string[]
}) {
    const result = props.insightsQueryResult
    if (result.isPending)
        return (
            <div>
                <LoadingPulse rows={2} height="h-80" />
            </div>
        )

    if (
        result.isError ||
        !result.data?.insights ||
        result.data?.insights.length === 0
    ) {
        return <Empty />
    }

    return (
        <div className="space-y-4">
            {result.data.insights.map((insight, index) => (
                <Insight
                    key={index}
                    insight={insight}
                    sinceDate={props.sinceDate!}
                    userIds={props.userIds}
                />
            ))}
        </div>
    )
}

function Empty() {
    return (
        <div className="flex items-center justify-center w-full h-64">
            <span className="bg-gray-200 p-2 rounded-lg text-gray-800">
                No insights to display
            </span>
        </div>
    )
}

function Insight(props: {
    insight: InsightResult
    sinceDate: string
    userIds: string[]
}) {
    const noData = !props.insight.lineChart && !props.insight.dealOutcomes
    return (
        <div
            className="flex flex-col bg-white rounded-lg p-6 border border-gray-200 w-full"
            id={props.insight.id} // Used by <Contents /> for navigation
        >
            <h2 className="text-2xl font-bold mb-3 text-gray-800">
                {props.insight.displayName}
            </h2>
            <div className="space-y-4">
                {noData && (
                    <p className="text-gray-500">
                        No data available for this insight.
                    </p>
                )}
                {props.insight.lineChart && (
                    <LineChart
                        insightName={props.insight.displayName}
                        data={props.insight.lineChart.data}
                        granularity={props.insight.lineChart.granularity}
                    />
                )}
                {props.insight.dealOutcomes && (
                    <DealOutcomesChart
                        insightId={props.insight.id}
                        insightName={props.insight.displayName}
                        data={props.insight.dealOutcomes.data}
                        sinceDate={props.sinceDate}
                        userIds={props.userIds}
                    />
                )}
            </div>
        </div>
    )
}

function BetaNotice() {
    return (
        <p className="text-sm text-gray-500 bg-yellow-50 border-2 border-yellow-200 p-2 rounded-lg w-fit">
            This is a beta feature in active development. If you have any
            questions or feedback{" "}
            <button
                className="text-blue-500 underline"
                onClick={async () =>
                    await submitPylonFeedback("Strategic Insights Feedback")
                }
            >
                click here to let us know
            </button>
            .
        </p>
    )
}

function DateSelect(props: {
    sinceDate: DateTime
    setSinceDate: (date: DateTime) => void
    setGranularity: (granularity: Granularity) => void
}) {
    const dateOptions = useMemo(
        () => [
            {
                label: "Last 30 days",
                value: DateTime.now().minus({ days: 30 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 3 months",
                value: DateTime.now().minus({ months: 3 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 6 months",
                value: DateTime.now().minus({ months: 6 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 9 months",
                value: DateTime.now().minus({ months: 9 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 12 months",
                value: DateTime.now().minus({ months: 12 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 18 months",
                value: DateTime.now().minus({ months: 18 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
        ],
        []
    )

    const currentOption = useMemo(() => {
        return (
            dateOptions.find(
                (option) =>
                    option.value?.toMillis() === props.sinceDate?.toMillis()
            ) || null
        )
    }, [props.sinceDate, dateOptions])

    return (
        <Select
            options={dateOptions}
            className="w-56"
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                    zIndex: 50,
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
            }}
            onChange={(option) => {
                if (!option) return
                props.setSinceDate(option.value)
                props.setGranularity(option.granularity)
            }}
            value={currentOption}
            isSearchable={false}
        />
    )
}

function PeopleSelect(props: {
    users?: IOrganizationUser[]
    selectedUserIds: string[]
    setSelectedUserIds: (userIds: string[]) => void
    isLoading: boolean
}) {
    const userToOption = (user: IOrganizationUser) => ({
        value: user.id,
        label: user.name || user.id,
    })

    const options = useMemo(() => {
        return (
            props.users
                ?.map(userToOption)
                .sort((a, b) => a.label.localeCompare(b.label)) || []
        )
    }, [props.users])

    const value = useMemo(() => {
        return (
            props.users
                ?.filter((user) => props.selectedUserIds.includes(user.id))
                .map(userToOption) || []
        )
    }, [props.selectedUserIds, props.users])

    return (
        <MultiSelectFilter
            title="people"
            icon="👤"
            options={options}
            value={value}
            onApply={(selectedOption) => {
                props.setSelectedUserIds(
                    selectedOption.map((option) => option.value)
                )
            }}
            isLoading={props.isLoading}
        />
    )
}
