import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { useNotification } from "../../providers/NotificationProvider"
import { ICRMDeal } from "../../types/Deal"
import { getSimpleDate } from "../../utils/datetime"
import { SecondaryButton } from "../common/Buttons"
import { LogoLink } from "../common/LogoLink"
import { NotificationType } from "../common/Notifcations"
import { SimpleCard } from "../common/SimpleCard"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

const maxDealsDisplayed = 3

export function CRMDeals(props: { companyDomain: string }) {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const [hasCRMIntegration, setHasCRMIntegration] = useState<boolean>(true)
    const [deals, setDeals] = useState<ICRMDeal[]>([])
    const { addNotification } = useNotification()

    useEffect(() => {
        async function getDeals() {
            try {
                setIsLoading(true)
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}/deals`
                )
                setDeals(data)
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 412
                ) {
                    setHasCRMIntegration(false)
                } else {
                    addNotification(
                        "Failed to load company deals",
                        "",
                        NotificationType.Error
                    )
                }
            } finally {
                setIsLoading(false)
            }
        }
        getDeals()
    }, [props.companyDomain, addNotification])

    const needsCollapseState = deals.length > maxDealsDisplayed
    const dealsToDisplay = useMemo(() => {
        return isCollapsed ? deals.slice(0, maxDealsDisplayed) : deals
    }, [isCollapsed, deals])

    if (!hasCRMIntegration) {
        return <MissingCRMIntegration />
    } else if (isLoading) {
        return <LoadingDeal />
    } else if (!deals?.length) {
        return <MissingDeal />
    }

    return (
        <div className="w-full flex flex-col gap-2">
            <div className="flex flex-col gap-2">
                {dealsToDisplay.map((deal) => (
                    <Deal key={deal.id} deal={deal} />
                ))}
            </div>
            {needsCollapseState && (
                <div className="flex justify-center font-semibold text-sm">
                    <button
                        className="p-1 place-self-end text-gray-800 text-sm font-semibold leading-tight tracking-tight outline-none"
                        onClick={() => setIsCollapsed((prev) => !prev)}
                    >
                        {isCollapsed ? "View more deals" : "View fewer deals"}{" "}
                        {isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        )}
                    </button>
                </div>
            )}
        </div>
    )
}

function MissingCRMIntegration() {
    return (
        <SimpleCard className="min-h-[70px]">
            <span className="flex p-2 space-x-6 items-center">
                <span className="w-1/2 text-base text-gray-600">
                    Connect Glyphic to your CRM to see deals with this company
                </span>
                <HashLink smooth to="/settings#crm">
                    <SecondaryButton>Settings</SecondaryButton>
                </HashLink>
            </span>
        </SimpleCard>
    )
}

function LoadingDeal() {
    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="flex animate-pulse">
                    <CRMDealAttribute
                        label="Deal"
                        value="Loading deal from CRM"
                    />
                </div>
            </div>
        </SimpleCard>
    )
}

function MissingDeal() {
    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="flex">
                    <CRMDealAttribute label="Deal" value="No CRM Deal yet" />
                </div>
            </div>
        </SimpleCard>
    )
}

function Deal(props: { deal: ICRMDeal }) {
    const deal = props.deal
    const closeDate = deal.close_date ? getSimpleDate(deal.close_date) : null
    const amount = deal.amount?.toLocaleString()
    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="grid md:grid-flow-col grid-cols-2 md:grid-cols-none md:divide-x divide-gray-300 gap-1 w-full">
                    <CRMDealAttribute label="Deal" value={deal.name} />
                    <CRMDealAttribute label="Stage" value={deal.stage?.name} />
                    <CRMDealAttribute label="Close Date" value={closeDate} />
                    <CRMDealAttribute label="Owner" value={deal.owner?.name} />
                    <CRMDealAttribute label="Amount" value={amount} />
                </div>
                <div className="p-1 md:p-0">
                    <CRMLogo url={deal.crm_url} />
                </div>
            </div>
        </SimpleCard>
    )
}

function CRMLogo({ url }: { url: string | null }) {
    if (!url) return null

    return (
        <LogoLink
            url={url}
            logo={
                <FontAwesomeIcon
                    className="px-2"
                    icon={crmIconFromUrl(url)}
                    size="lg"
                />
            }
        />
    )
}

export function CRMDealAttribute(props: {
    label: string
    value: string | null | undefined
}) {
    return (
        <div className="py-2 px-5 space-y-1">
            <div className="text-gray-900 text-xs font-semibold leading-none tracking-tight">
                {props.label}
            </div>
            <div className="text-gray-900 text-xs md:text-base font-normal leading-normal">
                {props.value || "Unspecified"}
            </div>
        </div>
    )
}
