import { useEffect, useState } from "react"
import { useUser } from "../../providers/UserProvider"
import { ICall } from "../../types/Call"
import LoadingSpinner from "../common/LoadingSpinner"
import CallDetailsTable from "./CallDetailsTable"
import FilterInput from "./FilterInput"
import { FilterTerm } from "./types/FilterTerm"
import { extractDomain } from "./utils/callInfo"
import { fetchCalls } from "./utils/fetchCalls"
import { TabHead } from "../common/Tabs"
import { useQuery } from "@tanstack/react-query"
import { ErrorPage } from "../common/errorPage"
import ToggleButton from "../common/ToggleButton"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { useSearchParam } from "../common/hooks/useSearchParam"
import { PageNavigator } from "./PageNavigator"
import { IPaginatedResponse } from "../../types/pagination"
import { Modal } from "../common/Modal"
import { CallUploadForm } from "./call-upload/CallUploadForm"
import { useNavigate } from "react-router-dom"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"
import { TagFilter } from "./TagFilter"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"

enum Tabs {
    YourCalls = "Your Calls",
    TeamCalls = "Team Calls",
    AllCalls = "All Calls",
}
interface Tab {
    type: Tabs
    label: string
}

const TAB_CACHE_KEY = "calls-list-tab-index"
const INTERNAL_TOGGLE_KEY = "calls-list-internal-toggle"

interface ITabViewProps {
    fetchEndpoint: string
    filterTerms: FilterTerm[]
    tags: string[]
    currentDomain: string | undefined
    includeInternalCalls: boolean
}

export function CallsList() {
    const navigate = useNavigate()
    const [tabs, setTabs] = useState<Tab[]>([])
    const [activeTab, setActiveTab] = useState(getCacheValue(TAB_CACHE_KEY, 0))
    const [showCallUploadModal, setShowCallUploadModal] = useState(false)
    const currentUser = useUser()
    const canViewOthersCalls =
        currentUser && hasPermission(currentUser, Permission.VIEW_OTHERS_CALLS)
    const currentDomain = extractDomain(currentUser?.email)

    const [filterUrlParam, setFilterUrlParam] = useSearchParam("filter")
    const [tagsUrlParam, setTagsUrlParam] = useSearchParam("tags")
    const urlFilterTerms: FilterTerm[] =
        filterUrlParam
            ?.split(",")
            .filter((term) => !!term)
            .map(
                (term): FilterTerm => ({
                    value: term,
                    accepted: true,
                })
            ) ?? []
    const selectedTags: string[] =
        tagsUrlParam?.split(",").filter((tag) => !!tag) ?? []

    // We need a separate state from the urlFilterTerms so we can represent the
    // `accepted` state of the filter terms in the UI.
    const [filterTerms, setFilterTerms] = useState<FilterTerm[]>(
        urlFilterTerms ?? []
    )
    const [includeInternalCalls, setIncludeInternalCalls] = useState(
        getCacheValue(INTERNAL_TOGGLE_KEY, true)
    )

    useEffect(() => {
        document.title = "Calls - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    useEffect(() => {
        const availableTabs = []
        if (canViewOthersCalls) {
            availableTabs.push({
                type: Tabs.AllCalls,
                label: Tabs.AllCalls.toString(),
            })
            availableTabs.push({
                type: Tabs.TeamCalls,
                label: Tabs.TeamCalls.toString(),
            })
        }

        availableTabs.push({
            type: Tabs.YourCalls,
            label: Tabs.YourCalls.toString(),
        })

        if (activeTab >= availableTabs.length) {
            setActiveTab(0)
        }

        setTabs(availableTabs)
    }, [canViewOthersCalls, activeTab])

    useEffect(() => {
        if (!filterUrlParam && filterTerms.length > 0) {
            // This is here to track the case when the user types in the filter
            // and then navigates to "Calls" tab again, which should clear the
            // filter.
            // We cannot yet always follow the state of the URL, because there
            // is no way to represent the accepted state.
            // The accepted state decides when a term should be its own tag
            // in the filter input (with the X to clear).
            // We could use the ',' at the end to represent the accepted state,
            // but it would complicate the logic!
            setFilterTerms([])
        }
    }, [filterUrlParam, filterTerms])

    const handleGlobalFilterChange = (newFilterTerms: FilterTerm[]) => {
        setFilterTerms(newFilterTerms)
        setFilterUrlParam(newFilterTerms.map((term) => term.value).join(","))
    }

    const handleTagChange = (newTags: string[]) => {
        setTagsUrlParam(newTags.join(","))
    }

    const canUploadCalls =
        currentUser && hasPermission(currentUser, Permission.RECORD_CALLS)

    return (
        <section className="my-6 px-8 py-6 space-y-4">
            <Modal
                isOpen={showCallUploadModal}
                onClose={() => setShowCallUploadModal(false)}
            >
                <CallUploadForm
                    onSuccess={(callId: string) => {
                        setShowCallUploadModal(false)
                        navigate(`/calls/${callId}`)
                    }}
                />
            </Modal>
            <div className="rounded-lg flex flex-col w-full gap-4">
                <div className="flex justify-between items-center gap-2">
                    <div className="w-auto">
                        <TabHead
                            tabs={tabs}
                            activeTab={activeTab}
                            onTabChange={(index: number) => {
                                setActiveTab(index)
                                setCacheValue(TAB_CACHE_KEY, index)
                            }}
                        />
                    </div>
                    {canUploadCalls && (
                        <div>
                            <SecondaryButton
                                className="flex gap-2 items-center"
                                onClick={() => setShowCallUploadModal(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faCirclePlus}
                                    className="w-5 h-5"
                                />
                                <span>Upload call</span>
                            </SecondaryButton>
                        </div>
                    )}
                </div>
                <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
                    <div className="w-full md:w-[400px] xl:w-[500px]">
                        <FilterInput
                            filterTerms={filterTerms}
                            placeholder="Filter by call title, participants, date..."
                            onFilterChange={handleGlobalFilterChange}
                            className="bg-white w-full text-base"
                        />
                    </div>
                    <div className="hidden md:block">
                        <TagFilter
                            onTagChange={handleTagChange}
                            selectedTags={selectedTags}
                        />
                    </div>
                    <div className="flex items-center gap-2 whitespace-nowrap">
                        <ToggleButton
                            checked={includeInternalCalls}
                            onChange={(newValue) => {
                                setIncludeInternalCalls(newValue)
                                setCacheValue(INTERNAL_TOGGLE_KEY, newValue)
                            }}
                        />
                        <span className="text-base">Include internal</span>
                    </div>
                </div>
            </div>
            <div className="space-y-4">
                {currentUser === undefined || tabs.length === 0 ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {tabs[activeTab].type === Tabs.YourCalls && (
                            <TabView
                                fetchEndpoint="/calls/user/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                            />
                        )}
                        {tabs[activeTab].type === Tabs.TeamCalls && (
                            <TabView
                                fetchEndpoint="/calls/team/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                            />
                        )}
                        {tabs[activeTab].type === Tabs.AllCalls && (
                            <TabView
                                fetchEndpoint="/calls/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                            />
                        )}
                    </>
                )}
            </div>
        </section>
    )
}

export function TabView({
    fetchEndpoint,
    filterTerms,
    tags,
    currentDomain,
    includeInternalCalls,
}: ITabViewProps) {
    const [currentPage, setCurrentPage] = useState(0)
    // Currently if there is any filters we disable pagination and load all calls
    // so that we can filter them on the client side.
    const usePagination = filterTerms.length === 0

    const queryKey = usePagination
        ? [fetchEndpoint, includeInternalCalls, currentPage, tags]
        : [fetchEndpoint, includeInternalCalls, tags]
    const {
        data: callResponse,
        isError,
        isPending,
    } = useQuery<IPaginatedResponse<ICall>>({
        queryKey: queryKey,
        queryFn: () =>
            fetchCalls(
                fetchEndpoint,
                includeInternalCalls,
                usePagination,
                currentPage,
                tags
            ),
        refetchInterval: 60000, // 60 seconds
    })

    if (isPending) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorPage error={{ message: "Failed to load calls" }} />
    }

    const calls = callResponse.records
    const pageCount = callResponse.pagination.page_count
    const pageSize = callResponse.pagination.page_size

    return (
        <div>
            <CallDetailsTable
                calls={calls}
                filterTerms={filterTerms}
                currentDomain={currentDomain}
                pageSize={pageSize}
            />
            <PageNavigator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
            />
        </div>
    )
}
