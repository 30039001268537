export enum Granularity {
    WEEKLY = "weekly",
    MONTHLY = "monthly",
}

export interface InsightsResponse {
    since: string
    until: string
    insights: InsightResult[]
}

export interface InsightResult {
    id: string
    displayName: string
    lineChart: LineChart | null
    dealOutcomes: DealOutcomes | null
}

export interface LineChart {
    granularity: Granularity
    data: LineChartBucket[]
}
export interface LineChartBucket {
    key: string
    values: Record<string, number>
}

export interface DealOutcomes {
    data: InsightValueDealOutcomes[]
}

export interface InsightValueDealOutcomes {
    value: string
    won: number
    lost: number
    won_deal_ids: string[]
    lost_deal_ids: string[]
}

export interface LossReason {
    loss_category: string
    description: string
    count: number
    deal_ids: string[]
}

export interface ILossReasons {
    loss_reasons: LossReason[]
    total_lost_deals: number
}
