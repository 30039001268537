import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { SessionAuth, signOut } from "supertokens-auth-react/recipe/session"
import logo from "../assets/logomark_sun_logotype_cotton.svg"
import { IntegrationAppSession } from "./IntegrationAppSession"
import { UserProvider, useUser } from "../providers/UserProvider"
import { UserInitialsCircle } from "./UserCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faArrowRightFromBracket,
    faGear,
    faCalendarAlt,
    faBook,
    faAnglesRight,
    faLandmark,
    faBell,
    faChartSimple,
    faHandshakeSimple,
    faHouse,
    faCircleQuestion,
    faLineChart,
} from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "react-tooltip"
import { FeatureFlags, canUseFeature } from "../utils/FeatureFlags"
import { ScreenSize, screenLargerOrEqualTo } from "../utils/screenSize"
import {
    displayReleaseNotesModal,
    displayReleaseNotesModalIfNew,
} from "../utils/releasenotes"
import {
    SearchModalProvider,
    useSearchModal,
} from "../providers/SearchProvider"
import { RecordingIcon } from "../assets/icons/RecordingIcon"
import { FrigadeProvider } from "./Frigade"
import { faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { NewVersionChecker } from "./NewVersionChecker"
import { AnnouncementChecker } from "./AnnouncementChecker"
import { hasPermission } from "../utils/Permissions"
import { Permission } from "../types/Permission"
import { showPylon } from "../utils/pylon"

export function WithNavbarLayout(props: { children: React.ReactNode }) {
    // Adds the Navbar and Supertokens session auth to a component
    return (
        <SessionAuth>
            <UserProvider>
                <FrigadeProvider>
                    <IntegrationAppSession>
                        <SearchModalProvider>
                            <div className="flex h-screen">
                                <Navbar />
                                {/* Padding matches navbar collapsed/expanded sizes */}
                                <div className="flex-grow pl-12 md:pl-48 max-w-full transition-all">
                                    {props.children}
                                </div>
                                {/* This Tooltip is used by any component that needs to
                        provide more explanation to the user.
                        We add it here so that's it's unique available to all
                        components in this layout.
                        Keeping it available to multiple components is good
                        practice, see https://github.com/ReactTooltip/react-tooltip#dynamically-generated-anchor-elements */}
                                <Tooltip
                                    id="tooltip-explanation"
                                    className="z-[60] max-w-xl text-left"
                                    style={{
                                        backgroundColor: "#111827",
                                        borderRadius: "8px",
                                    }}
                                />
                                <NewVersionChecker />
                                <AnnouncementChecker />
                            </div>
                        </SearchModalProvider>
                    </IntegrationAppSession>
                </FrigadeProvider>
            </UserProvider>
        </SessionAuth>
    )
}

function Navbar() {
    const user = useUser()
    const canViewOthersCalls =
        user && hasPermission(user, Permission.VIEW_OTHERS_CALLS)
    const location = useLocation()
    const [isExpanded, setIsExpanded] = useState<boolean>(
        screenLargerOrEqualTo(ScreenSize.md)
    )

    function toggleIsExpanded() {
        setIsExpanded((prev) => !prev)
    }

    const handleResize = () => {
        setIsExpanded(screenLargerOrEqualTo(ScreenSize.md))
    }

    useEffect(() => {
        handleResize() // Call on mount to set initial state
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <div
            className={`text-base flex flex-col h-full overflow-y-auto my-auto py-4 fixed bg-gray-900 transition-all z-20 ${
                isExpanded ? "w-48 px-4" : "w-12 px-2"
            }`}
        >
            <div className="flex flex-col justify-between h-full object-scale-down">
                {/* Top items */}
                <div className="space-y-1 py-2">
                    <div className="pb-5 flex space-x-2">
                        {isExpanded && <Logo />}
                        <button
                            className="md:hidden flex text-white rounded-lg p-2 hover:bg-gray-700 transition-colors"
                            onClick={toggleIsExpanded}
                        >
                            <FontAwesomeIcon
                                icon={faAnglesRight}
                                className={`transition-all ${
                                    isExpanded ? "rotate-180" : ""
                                }`}
                            />
                        </button>
                    </div>
                    <div className="space-y-2">
                        {canViewOthersCalls && (
                            <Search showLabel={isExpanded} />
                        )}
                        <Home
                            pathname={location.pathname}
                            showLabel={isExpanded}
                        />
                        <Calls
                            pathname={location.pathname}
                            showLabel={isExpanded}
                        />
                        {canViewOthersCalls && (
                            <Deals
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                        )}
                        {canViewOthersCalls && (
                            <Companies
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                        )}
                        <Schedule
                            pathname={location.pathname}
                            showLabel={isExpanded}
                        />
                        {canViewOthersCalls && (
                            <Activity
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                        )}
                        {canViewOthersCalls && (
                            <StrategicInsights
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                        )}
                        {canUseFeature(
                            FeatureFlags.ShowResources,
                            user?.organizationId
                        ) && (
                            <Resources
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                        )}
                    </div>
                </div>
                {/* Bottom items */}
                <div className="space-y-1">
                    <UserLabel showLabel={isExpanded} />
                    <Updates showLabel={isExpanded} />
                    <Help showLabel={isExpanded} />
                    <Settings
                        pathname={location.pathname}
                        showLabel={isExpanded}
                    />
                    <LogOut showLabel={isExpanded} />
                </div>
            </div>
        </div>
    )
}

function Logo() {
    return (
        <Link className="flex items-center space-x-2" to="/home">
            <img src={logo} alt="Logo" className="max-h-[32px]" />{" "}
        </Link>
    )
}

function UserLabel(props: { showLabel: boolean }) {
    const user = useUser()
    if (!user) {
        return <></>
    }
    const firstName = user.name.split(" ")[0]

    return (
        <div className="flex items-center space-x-2 px-1 py-2">
            <UserInitialsCircle name={user.name} size="24px" />
            {props.showLabel && <span className="text-white">{firstName}</span>}
        </div>
    )
}

function Home(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Home"
            icon={<FontAwesomeIcon icon={faHouse} />}
            to="/home"
            selected={props.pathname === "/home"}
            showLabel={props.showLabel}
        />
    )
}

function Companies(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Companies"
            icon={<FontAwesomeIcon icon={faLandmark} />}
            to="/companies"
            selected={props.pathname.startsWith("/companies")}
            showLabel={props.showLabel}
        />
    )
}

function Calls(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Calls"
            icon={<RecordingIcon />}
            to="/calls"
            selected={props.pathname.startsWith("/calls")}
            showLabel={props.showLabel}
        />
    )
}

function Deals(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Deals"
            icon={<FontAwesomeIcon icon={faHandshakeSimple} />}
            to="/deals"
            selected={props.pathname.startsWith("/deals")}
            showLabel={props.showLabel}
        />
    )
}

function Schedule(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Schedule"
            icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            to="/schedule"
            selected={props.pathname.startsWith("/schedule")}
            showLabel={props.showLabel}
        />
    )
}

function Activity(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Activity"
            icon={<FontAwesomeIcon icon={faChartSimple} />}
            to="/activity"
            selected={props.pathname.startsWith("/activity")}
            showLabel={props.showLabel}
        />
    )
}

function Resources(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Resources"
            icon={<FontAwesomeIcon icon={faBook} />}
            to="/resources"
            selected={props.pathname.startsWith("/resources")}
            showLabel={props.showLabel}
        />
    )
}

function Settings(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            to="/settings"
            icon={<FontAwesomeIcon icon={faGear} size="lg" />}
            label="Settings"
            selected={props.pathname.startsWith("/settings")}
            showLabel={props.showLabel}
        />
    )
}

function StrategicInsights(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            to="/insights"
            icon={<FontAwesomeIcon icon={faLineChart} size="lg" />}
            label="Insights"
            selected={props.pathname.startsWith("/insights")}
            showLabel={props.showLabel}
            isBeta={true}
        />
    )
}

function Search(props: { showLabel: boolean }) {
    const { openSearchModal } = useSearchModal()
    const shortcutKey = isMacOS() ? "⌘K" : "ctrl+k"
    return (
        <div
            onClick={openSearchModal}
            className="flex p-2 rounded-lg justify-between items-center text-white font-semibold hover:cursor-pointer  hover:bg-gray-700 transition-colors"
        >
            <div className="space-x-2">
                <FontAwesomeIcon icon={faSparkles} size="lg" />
                {props.showLabel && <span>Search</span>}
            </div>
            {props.showLabel && (
                <span className="opacity-50">{shortcutKey}</span>
            )}
        </div>
    )
}

function isMacOS() {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes("mac")
}

function Updates(props: { showLabel: boolean }) {
    useEffect(() => {
        displayReleaseNotesModalIfNew()
    }, [])

    return (
        <div
            onClick={displayReleaseNotesModal}
            className="flex p-2 rounded-lg space-x-2 items-center text-white hover:cursor-pointer  hover:bg-gray-700 transition-colors rn-badge" // rn-badge needed to display unread notifiation
        >
            <FontAwesomeIcon icon={faBell} size="lg" />
            {props.showLabel && <span>Updates</span>}
        </div>
    )
}

function Help(props: { showLabel: boolean }) {
    return (
        <div
            onClick={showPylon}
            className="flex p-2 rounded-lg space-x-2 items-center text-white hover:cursor-pointer  hover:bg-gray-700 transition-colors rn-badge" // rn-badge needed to display unread notifiation
        >
            <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
            {props.showLabel && <span>Help</span>}
        </div>
    )
}

function PageLink(props: {
    label: string
    icon: React.ReactNode
    to: string
    selected: boolean
    showLabel: boolean
    isBeta?: boolean
}) {
    return (
        <Link
            to={props.to}
            className={
                "flex flex-row items-center gap-2 text-white font-semibold p-2 rounded-lg hover:bg-gray-700 transition-colors " +
                (props.selected ? "bg-gray-700 font-bold" : "bg-gray-900")
            }
        >
            {props.icon}
            {props.showLabel && (
                <span>
                    {props.label}
                    {props.isBeta && (
                        <span className="text-sm font-light"> (Beta)</span>
                    )}
                </span>
            )}
        </Link>
    )
}

function LogOut(props: { showLabel: boolean }) {
    const navigate = useNavigate()

    async function logoutClicked() {
        await signOut()
        navigate("/auth")
    }

    return (
        <div
            onClick={logoutClicked}
            className="flex p-2 rounded-lg space-x-2 items-center text-white hover:cursor-pointer  hover:bg-red-500 transition-colors"
        >
            <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
            {props.showLabel && <span>Log out</span>}
        </div>
    )
}
