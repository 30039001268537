import { mergeQueryKeys } from "@lukemorales/query-key-factory"
import { callQueries } from "./calls"
import { upcomingCallQueries } from "./upcomingCalls"
import { userQueries } from "./users"
import { crmQueries } from "./crm"
import { organizationQueries } from "./organization"
import { callTagQueries } from "./callTags"
import { versionQueries } from "./version"
import { insightQueries } from "./insights"
import { announcementQueries } from "./announcements"
import { dealsQueries } from "./deals"

export const queries = mergeQueryKeys(
    organizationQueries,
    callQueries,
    callTagQueries,
    upcomingCallQueries,
    userQueries,
    crmQueries,
    versionQueries,
    announcementQueries,
    insightQueries,
    dealsQueries
)
